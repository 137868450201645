export const links = [
    {
      name: "Shop",
      submenu: true,
      sublinks: [
        {
          Head: "Shop All",
          link:"/rasiyaa"
          
        },
        {
          Head: "Lehenga sets",
          link:"/lehengas"
         
        },
        {
          Head: "Saree sets",
          link:"/sarees"
          // sublink: [
          //   { name: "T-shirt", link: "/" },
          //   { name: "Casual shirts", link: "/" },
          //   { name: "formal shirts", link: "/" },
          //   { name: "formal shirts", link: "/" },
          //   { name: "formal shirts", link: "/" },
          // ],
        },
  
        {
          Head: "Anarkali sets",
          link:"/anarkali"
          // sublink: [
          //   { name: "T-shirt", link: "/" },
          //   { name: "Casual shirts", link: "/" },
          //   { name: "formal shirts", link: "/" },
          //   { name: "formal shirts", link: "/" },
          //   { name: "formal shirts", link: "/" },
          // ],
        },
        {
          Head: "Sharara/gharara sets",
          link:"/sharara-gharara"
          // sublink: [
          //   { name: "T-shirt", link: "/" },
          //   { name: "Casual shirts", link: "/" },
          //   { name: "formal shirts", link: "/" },
          //   { name: "formal shirts", link: "/" },
          //   { name: "formal shirts", link: "/" },
          // ],
        },
      ],
    },
    {
      name: "Collections",
      submenu: true,
      sublinks: [
        {
          Head: "Rasiyaa",
          link:"/rasiyaa"
          // sublink: [
          //   { name: "T-shirt", link: "/" },
          //   { name: "Casual shirts", link: "/" },
          //   { name: "formal shirts", link: "/" },
          //   { name: "formal shirts", link: "/" },
          //   { name: "formal shirts", link: "/" },
          // ],
        }
        // ,
        // {
        //   Head: "Bottomwear",
        //   sublink: [
        //     { name: "T-shirt", link: "/" },
        //     { name: "Casual shirts", link: "/" },
        //     { name: "formal shirts", link: "/" },
        //     { name: "formal shirts", link: "/" },
        //     { name: "formal shirts", link: "/" },
        //   ],
        // },
        // {
        //   Head: "innerwear",
        //   sublink: [
        //     { name: "T-shirt", link: "/" },
        //     { name: "Casual shirts", link: "/" },
        //     { name: "formal shirts", link: "/" },
        //     { name: "formal shirts", link: "/" },
        //     { name: "formal shirts", link: "/" },
        //   ],
        // },
  
        // {
        //   Head: "sleepwear",
        //   sublink: [
        //     { name: "T-shirt", link: "/" },
        //     { name: "Casual shirts", link: "/" },
        //     { name: "formal shirts", link: "/" },
        //     { name: "formal shirts", link: "/" },
        //     { name: "formal shirts", link: "/" },
        //   ],
        // },
        // {
        //   Head: "footwear",
        //   sublink: [
        //     { name: "T-shirt", link: "/" },
        //     { name: "Casual shirts", link: "/" },
        //     { name: "formal shirts", link: "/" },
        //     { name: "formal shirts", link: "/" },
        //     { name: "formal shirts", link: "/" },
        //   ],
        // },
      ],
    }
    // {
    //   name: "Kid's",
    //   submenu: true,
    //   sublinks: [
    //     {
    //       Head: "Topwear",
    //       sublink: [
    //         { name: "T-shirt", link: "/" },
    //         { name: "Casual shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //       ],
    //     },
    //     {
    //       Head: "Bottomwear",
    //       sublink: [
    //         { name: "T-shirt", link: "/" },
    //         { name: "Casual shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //       ],
    //     },
    //     {
    //       Head: "innerwear",
    //       sublink: [
    //         { name: "T-shirt", link: "/" },
    //         { name: "Casual shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //       ],
    //     },
  
    //     {
    //       Head: "sleepwear",
    //       sublink: [
    //         { name: "T-shirt", link: "/" },
    //         { name: "Casual shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //       ],
    //     },
    //     {
    //       Head: "footwear",
    //       sublink: [
    //         { name: "T-shirt", link: "/" },
    //         { name: "Casual shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //       ],
    //     },
    //   ],
    // },
  ];
  