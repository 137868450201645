import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/Logo.png";
import { AiOutlineClose, AiOutlineInstagram, AiOutlineMenu } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import {  } from "react-icons/bi";

import NavLinks from "./NavLinks";
import Button from "../Button";
import { BiLogoGmail, BiLogoWhatsapp } from "react-icons/bi";
const Navbar = () => {
  const [open, setOpen] = useState(false);
  const handleWhatsappClick = () => {
    // Replace the phone number and message text as needed
    const phoneNumber = 8851314160;
    const message = 'Hello, this is a test message';
    const encodedMessage = encodeURIComponent(message);

    const whatsappUrl = `https://wa.me/message/TBJQTNLBNXQEJ1`;

    
    window.open(whatsappUrl, '_blank');
  };

  return (
    <nav className="bg-black z-50 relative text-white">
      <div className="flex items-center font-medium justify-around">
        <div className="z-50 p-5 md:w-auto w-full flex items-center justify-between">
          <div className="text-3xl md:hidden" onClick={() => setOpen(!open)}>
          { 
            open? <AiOutlineClose className='text-3xl   lg:hidden block' /> : 
            <AiOutlineMenu   className='text-3xl lg:hidden block  '/> 
            
          }
          </div>
          <Link to="/">

          <img src={Logo} alt="logo" className="md:cursor-pointer h-[55px]" />
          </Link>
          <button className="md:hidden block " onClick={handleWhatsappClick}>
        <BiLogoWhatsapp className="text-4xl text-green-500 "/>
        </button>
        
        </div>
        <ul className="md:flex hidden uppercase items-center gap-8 font-[sans-serif] text-xs tracking-wider">
          <li>
            <Link to="/" className="py-7 px-3 inline-block hover:underline">
              Home
            </Link>
          </li>
          <NavLinks open={open} setOpen={setOpen}     />
          <li>
            <Link to="/about" className="py-7 px-3 inline-block hover:underline">
              About us
            </Link>
          </li>
          <li>
            <Link to="/contactus" className="py-7 px-3 inline-block hover:underline">
              Contact us
            </Link>
          </li>
        </ul>
        <button className="md:block hidden" onClick={handleWhatsappClick}>
        <BiLogoWhatsapp className="text-4xl text-green-500 "/>
        </button>
        {/* Mobile nav */}
        <ul
          className={`
        md:hidden block bg-black text-white fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4
        duration-500 ${open ? "left-0" : "left-[-100%]"} 
        `}
        onClick={() => setOpen(!open)}
        >
          <li >
            <Link to="/" className="py-7 px-3 inline-block ">
              Home
            </Link>
          </li>
          <NavLinks />
          <li>
            <Link to="/about" className="py-7 px-3 inline-block">
              About us
            </Link>
          </li>
          <li>
            <Link to="/contactus" className="py-7 px-3 inline-block">
              Contact us
            </Link>
          </li>
          <div className=" flex gap-2 py-7 px-3">
          <button className="xs:block " onClick={handleWhatsappClick}>
        <BiLogoWhatsapp className="text-3xl  "/>
        </button>
        <button>
  <a href="https://instagram.com/mohbyrohitpatel?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noopener noreferrer">
    <AiOutlineInstagram className="text-3xl" />
  </a>
</button>
<button>
  <a href="https://www.facebook.com/profile.php?id=61550204004651&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
    <BsFacebook className="text-3xl " />
  </a>
</button>
{/* <button>
  <a href="mailto:Mohbyrohitpatel@gmail.com">
    <BiLogoGmail className="text-3xl " />
  </a>
</button> */}

          </div>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
