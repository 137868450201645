import React from 'react'

const ReturnExchange = () => {
  return (
    <div className='flex mx-4 flex-col justify-center h-auto my-12 items-center font-[sans-serif]'>
    
    <h1 className='mb-8 uppercase font-[serif] text-2xl '>Return and exchange</h1>
    <div className='text-start'>
      <p className='max-w-5xl mx-auto text-sm '>Our outfits are custom made
       as per client measurements. Once the order is confirmed and the advance payment is made,
        the order cannot be canceled.
We do not provide any return, refund or exchange.
Advance amount paid by the customer is non refundable.
It is advised to check for your size requirements before you make a purchase order.
</p>
      </div>
    </div>
  )
}

export default ReturnExchange