import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className='flex mx-4 flex-col justify-center h-auto my-8 items-center font-[sans-serif]'>
      
    <h1 className='my-8 lg:text-3xl text-2xl font-[serif]'>PRIVACY POLICY</h1>
    <div className='text-start'>

    <h3 className='my-6  text-l  font-semibold font-[futura]'>WHAT PERSONAL INFORMATION DO WE COLLECT FROM YOU AND HOW?
    </h3>
    <p className='max-w-5xl mx-auto text-sm '>
    (i) We collect personal information about you when you register or subscribe for one of our services, place an order, buy a gift voucher, use our online services, interact with us in any other way, such as via social media, click on an advert that we put on our or someone else’s website, use our Personal Shopping services, ask for information or assistance, give us a testimonial or other feedback, comment on any blogs or articles featured in our services, attend an event that we run or sponsor, enter competitions or special promotions, sign up for our special offers or other updates, participate in research panels or fill in surveys. By registering, subscribing or using our services, you consent to the use of your personal data in line with this policy. We may also receive information about you from our Group and from third parties, with whom we have a business relationship.
    </p>
    <p className='max-w-5xl mx-auto text-sm '>
    (ii) The information we collect and hold about you may include your name, email address, phone and mobile phone numbers, home address, shipping and payment card billing address, payment card details, IP address, search criteria, shopping history, shopping preferences, sizes, responses to research panels and surveys, the type of browser you use (a browser is the program you use to look at websites, such as Internet Explorer, Firefox or Safari), the times when you access the site and for how long, your referring URL (the site you come from to reach our website), cell IDs (these are the unique identifiers of the telecommunications towers being used by your mobile phone when you use our location-based services on your mobile phone or tablet) and other location information (for example, GPS measurements), photographs and other content you share with us when you use our services, date of birth, password details, answers to security questions and any other information you may give us. We may monitor calls that you receive from us or make to us. We do this for security and training purposes, and to improve the services we provide to you.
    </p>

    <h3 className='my-6  text-l  font-semibold font-[futura]'>Please also make note of the following points on our privacy policy.</h3>
    <p className='max-w-5xl mx-auto text-sm '>
    Your privacy is very important to us. Accordingly, we have developed this Policy in order for you to understand how we collect, use, communicate and disclose and make use of personal information. The following outlines our privacy policy.
    </p>
    <p className='max-w-5xl mx-auto text-sm '>
    <li className='text-sm' >Before or at the time of collecting personal information, we will identify the purposes for which information is being collected.</li>
    <li className='text-sm' >
    We will collect and use personal information solely with the objective of fulfilling those purposes specified by us and for other compatible purposes, unless we obtain the consent of the individual concerned, or as required by law.
    </li>
    <li className='text-sm' >
    We will only retain personal information as long as necessary for the fulfilment of those purposes.
    </li>
    <li className='text-sm' >
    We will collect personal information by lawful and fair means and, where appropriate, with the knowledge or consent of the individual concerned.
    </li>
    <li className='text-sm' >
    Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.
    </li>
    <li className='text-sm' >
    We will protect personal information against loss or theft with reasonable security safeguards, as well as unauthorised access, disclosure, copying, use or modification.
    </li>
    <li className='text-sm' >
    We will make readily available to customers information about our policies and practices relating to the management of personal information.
    </li>
    </p>

    <p className='max-w-5xl mx-auto text-sm my-3 font-semibold'>
    We are committed to conducting our business in accordance with these principles in order to ensure that the confidentiality of personal information is protected and maintained.
    </p>

    <h3 className='my-6  text-l  font-semibold font-[futura]'>
    WHO WE WILL SHARE YOUR PERSONAL INFORMATION WITH
    </h3>
    <p className='max-w-5xl mx-auto text-sm '>
    We might share your information with other companies/designers we wish to collaborate with, who may use it for their own business purposes, as set out in their privacy policy. These purposes may include financial reporting and analysis, strategic planning, the development of customer segmentation and metrics to provide a consistent view of our customer base, research and analytics, to help us source the right products in future and make better product recommendations, more effective targeting of our marketing campaigns, the creation of inspiring content and editorial features.
    </p>
    <p className='max-w-5xl mx-auto text-sm '>
    We may also pass your information onto one or more of the following organisations: (i) data processing companies, mailing houses and other third party suppliers working on our brand’s behalf; (ii) ad-serving agencies and other advertising intermediaries; (iii) credit reference or fraud prevention agencies, which may keep a record of that information; (iv) research students, universities and other research and development organisations; (v) regulatory bodies, government and enforcement agencies, such as the police.
    </p>
    
    <h3 className='my-6  text-l  font-semibold font-[futura]'>
    HOW YOU CAN ACCESS YOUR PERSONAL INFORMATION AND KEEP IT UP TO DATE
    </h3>
    <p className='max-w-5xl mx-auto text-sm '>
    You have the right to review and update your personal details. If for any reason you are concerned that the personal information we hold is not correct, you may contact us by email at <a href='mailto:Mohbyrohitpatel@gmail.com?subject=Hello%20from%20Gmail'> mohbyrhitpatel@gmail.com </a> and we will amend your personal details. 
    </p>
    <p className='max-w-5xl mx-auto text-sm '>
    Alternatively, you will have access towards edited all the information you provide us in your profile updated on ekaya.in.
    </p>

    <h3 className='my-6  text-l  font-semibold font-[futura]'>
    HOW WE CAN MAKE CHANGES TO THIS POLICY
    </h3>
    <p className='max-w-5xl mx-auto text-sm '>
    We may update this privacy policy from time to time, so you may want to check it each time you give us personal information or use our websites.
    </p>

    <h3 className='my-6  text-l  font-semibold font-[futura]'>
    QUESTIONS OR CONCERNS 
    </h3>
    <p className='max-w-5xl mx-auto text-sm '>
    If you have any questions or concerns regarding our privacy policies, please  send us a detailed message to <a href='mailto:Mohbyrohitpatel@gmail.com?subject=Hello%20from%20Gmail'> mohbyrhitpatel@gmail.com</a> We will make every effort to resolve your concerns.
    </p>
    </div>
    </div>
  )
}

export default PrivacyPolicy