import React, { useEffect, useState } from 'react';

const Loader = ({onLoadComplete}) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      // Simulate loading delay (you can replace this with actual data loading)
      setTimeout(() => {
        setLoading(false);
        onLoadComplete();
      }, 1000); // 2 seconds
    }, []);
  return (
    <>{
        loading? (

    <div className="flex justify-center items-center h-screen">
      {/* You can customize the loader's appearance */}
      <div className="animate-spin rounded-full border-t-4 border-blue-900 border-opacity-25 h-16 w-16"></div>
    </div>
        ):(
            <div>content loaded</div>
        )
    }
    </>
  );
};

export default Loader;