export const ShararaImg = [
   
      {
        imageUrl: 'Images/Rasiyaa/14.jpg',
                  title: "chanderi jhali gharara set",
                  price:"18,000"
      },
    
      {
        imageUrl: 'Images/Rasiyaa/7.jpg',
                  title: " Brocade chanderi gharara set",
                  price:"18,000"
      },
     
     
     
      // Add more image objects as needed
    ];
      