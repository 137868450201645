import React, { useEffect, useState } from 'react'
import { RasiyaaImg } from '../Components/RasiyaImages'
import Loader from '../Components/Loader';
import SortOptions from '../Components/SortOptions';
import _ from 'lodash';
import SizeChartModal from '../Components/SizeChartModal';
import ProductHeader from '../Components/Navbar/ProductHeader';

const Rasiyaa = () => {
  const[isLoading, setIsLoading] =useState(true);
  const [sortedProducts, setSortedProducts] = useState(RasiyaaImg);
  


  const handleLoadComplete=()=>{
    setIsLoading(false);
  }

 
 
  return (
    <>
    {isLoading ? ( // Display the loader while loading
    <Loader onLoadComplete={handleLoadComplete} />
    ) : (
            
      <div>
         <ProductHeader
         sortedProducts={sortedProducts}
         setSortedProducts={setSortedProducts}
        />
      
          <div className='grid grid-cols-2 md:grid-cols-4 gap-4 mt-8 mx-6'>

        {sortedProducts.map((image, index) => (
          <div key={index} className=' p-4'>
            <img
              src={image.imageUrl}
              alt={image.title}
              className='object-cover w-full h-auto shadow-md mb-4 transition-transform duration-300 transform hover:scale-110'
            />
            <h5 className='text-xs md:text-sm my-8 text-black-500 uppercase text-center uppercase font-small mb-2 tracking-wide'>{image.title}</h5>
            <p className='text-center text-xs'>Rs. {image.price}</p>
            
            
          </div>
        ))}
      </div>
    </div>
  )}
  </>
  )
}

export default Rasiyaa