import React from 'react'

const About = () => {
  return (
    <div className='flex flex-col  justify-center h-auto my-8 items-center font-[sans-serif]'>
    
    <h1 className='mb-6 uppercase font-[serif] text-2xl '>About US</h1>
    <div className='text-start'>
      <p className='max-w-5xl mx-auto text-sm  '>
      Moh by Rohit Patel is a Udaipur based brand which encapsulates the rich tapestry of Indian culture, weaving together the time-honored traditions of the past with the contemporary aesthetics of the present. Each design is a seamless fusion of traditional elegance and modern allure where Gotta Patti embroidery tells a story of heritage, and Rajasthan's regal magnificence is seamlessly integrated into every silhouette.
      </p>
      </div>
    </div>
  )
}

export default About