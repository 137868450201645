import React from 'react';

const Tnc = () => {
  return (
    <div className='flex mx-4 flex-col justify-center h-auto my-8 items-center font-[sans-serif]'>
      

        <h1 className='mb-6 uppercase font-[serif] text-2xl my-8'>TERMS AND CONDITIONS</h1>
        <div className='text-start'>

        <p className='max-w-5xl mx-auto text-sm '>
          www.mohbyrohitpatel.com is the place where we value and share your concerns about your privacy and security. By visiting us, the user has agreed to read, browse, and access all our information subject to the guidelines and terms of use applicable to such services. If it is not acceptable, kindly refrain from accessing or visiting our site.
          If you visit or shop at www.mohbyrohitpatel.com, you accept these conditions. Please read them carefully. In addition, when you use any current or future online service or visit or purchase from any business affiliated with mohbyrohitpatel, whether or not included in the official website, you also will be subject to the guidelines and conditions applicable to such service or business.
        </p>

        <h2 className='mt-6 text-lg font-semibold'>COPYRIGHT</h2>

        <p className='max-w-5xl mx-auto text-sm '>
          All content included on this site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of mohbyrohitpatel official website or its content suppliers. The compilation of all content on this site is the exclusive property of mohbyrohitpatel. All software used on this site is the property of mohbyrohitpatel or its software suppliers.
        </p>

        <h2 className='mt-6 text-lg font-semibold'>TRADEMARKS</h2>

        <p className='max-w-5xl mx-auto text-sm '>
          Your use of the website and its contents grants no rights to you in relation to any copyright, designs, trademarks, and all other intellectual property and material rights, including Mohbyrohitpatel’s Software and all HTML and other code contained in this Site. Any violation of our trademarks will be liable for legal action.
        </p>

        <h2 className='mt-6 text-lg font-semibold'> SIZE</h2>
        <p className='max-w-5xl mx-auto text-sm '>Our size chart is visible on each product. We also customize as per the size. However, There is an additional charge of 5%, 10% and 15% for XL, 2XL and 3XL sizes respectively. </p>

        <h2 className='mt-6 text-lg font-semibold'> COLORS</h2>
        <p className='max-w-5xl mx-auto text-sm '>While we have made every effort to display as accurately as possible the colors of the products that appear on the Site, we cannot guarantee that your monitor or screen’s display of any color will be completely accurate, as computer monitors and screens of electronic devices vary. 
Color, embroidery, cuts, styles, fabric may vary slightly to the original sample shown. As the mirrors are embroidered to the fabric a slight fall out of the mirror while wearing/ packing / unpacking is natural. </p>

<h2 className='mt-6 text-lg font-semibold'> ELECTRONIC COMMUNICATIONS</h2>
        <p className='max-w-5xl mx-auto text-sm '>When You use the Site or send emails or other data, information or communication to us, You agree and understand that You are communicating with us through electronic records and You consent to receive communications via electronic records from us periodically and as and when required. We will communicate with You by email or by an electronic record on our Site which will be deemed adequate service of notice / electronic record.
</p>
<h2 className='mt-6 text-lg font-semibold'> CANCELLATIONS BY THE USER</h2>
        <p className='max-w-5xl mx-auto text-sm '>Our outfits are custom made as per client measurements. Once the order is confirmed and the advance payment is made, the order cannot be canceled.
We do not provide any return, refund or exchange.
Advance amount paid by the customer is non refundable.</p>

<h2 className='mt-6 text-lg font-semibold'>ALTERATIONS</h2>
<p className='max-w-5xl mx-auto text-sm '>We do alterations only in India. So you will have to send the outfit back to us and then you can have it couriered back to you at your own expense.
We don’t provide alterations services for international clients. Whatever  measurements you give us is with what we will send the outfit to you with enough margins for you to get it altered locally.
</p>

<h2 className='mt-6 text-lg font-semibold'> BILLING</h2>
        <p className='max-w-5xl mx-auto text-sm '>The price of our merchandise is inclusive of the GST. The Freight charged shall depend upon the destination where the order has to be shipped. We reserve the right to collect taxes for shipping charges wherever applicable.
To confirm and place an order, full payment is required in advance. If the advance amount is not paid within the mentioned time period, then the order stands to be put on hold or canceled.
Advance amount paid by the customer is non refundable.
</p>



      </div>
    </div>
  );
};

export default Tnc;
