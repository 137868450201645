import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import SizeChartModal from '../SizeChartModal';
import SortOptions from '../SortOptions';


const ProductHeader = ({sortedProducts, setSortedProducts}) => {
    // console.log(sortedProducts);
    const [isSizeChartOpen, setSizeChartOpen] = useState(false);
    // const [sortedProducts, setSortedProducts] = useState([]);
  const [sortingOption, setSortingOption] = useState('');

    // useEffect(()=>{
    //     setSortedProducts(sortedProducts)
    // },[])

    const toggleSizeChart = () => {
        setSizeChartOpen(!isSizeChartOpen);
      };
      useEffect(() => {
        if(sortingOption){
            console.log(sortingOption)
          switch(sortingOption){
            case "alphabetic-a-z":
            //   console.log("working");
            setSortedProducts(_.sortBy(sortedProducts, ['title']));
              break;
              case 'alphabetic-z-a':
                setSortedProducts(_.sortBy(sortedProducts, ['title']).reverse());
                break;
              case 'price-low-to-high':
                setSortedProducts(_.sortBy(sortedProducts, ['price']));
                break;
              case 'price-high-to-low':
                setSortedProducts(_.sortBy(sortedProducts, ['price']).reverse());
                break;
              default:
                setSortedProducts(sortedProducts);
                break;
        }
          }
          else {
            setSortedProducts(sortedProducts);
          }
          console.log(sortedProducts);
          },[sortingOption]
          )
         

  return (
    <div >
       
           <div className='flex my-8 mx-6 justify-evenly  items-center'>
        <h1 className='text-xs md:text-sm    tracking-wide'>{sortedProducts.length} Products</h1>
        

        <div className=""> {/* Added a parent flex container */}
    <button className="hover:underline" onClick={toggleSizeChart}>
          Size Chart   
    </button>
    <SizeChartModal isOpen={isSizeChartOpen} onClose={toggleSizeChart} />
    </div>
<SortOptions
onSortChange={(e) => {
  console.log(e.target.value)
   setSortingOption(e.target.value)}}
sortingOption={sortingOption}/>
        </div>
    </div>
  )
}

export default ProductHeader