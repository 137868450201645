import React from 'react'
import { A, AiOutlineClose} from "react-icons/ai";

const SizeChartModal = ({isOpen, onClose}) => {
  return (
    <div>
          <div
      className={`fixed inset-0 z-50 flex items-center justify-center ${
        isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
      } transition-opacity duration-300`}
    >
      {/* Modal */}
      <div className="bg-white rounded-xl shadow-lg p-4 w-4/5 md:w-3/5 lg:w-2/5">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-sm font-semibold items-center">Size Chart</h2>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
           <AiOutlineClose/>
          </button>
        </div>
        <div>

        <h2 className="uppercase text-sm  font-[serif] text-center semi-bold my-4">Measurement Guidelines</h2>
        </div>
        <div className="my-4 mx-4 text-xs">
          <ul className="list-disc list-inside">
            <li>Measurement should be taken directly on your body.</li>
            <li>Measure around the fullest part of your bust with arms down by your side.</li>
            <li>Measure around the narrowest part of your waistline, above navel and below ribcage.</li>
            <li>Measure around the fullest part of your hips.</li>
          </ul>
        </div>
        <div className="overflow-x-auto">
        <h2 className="uppercase text-sm  font-[serif] semi-bold text-center my-4">Size guide(In inches)</h2>
          <table className="table-auto mx-4">
            <thead>
              <tr>
                <th className="px-4 py-2"></th>
                <th className="px-4 py-2">XS</th>
                <th className="px-4 py-2">S</th>
                <th className="px-4 py-2">M</th>
                <th className="px-4 py-2">L</th>
                <th className="px-4 py-2">XL</th>
                <th className="px-4 py-2">XXL</th>

              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-4 py-2">Bust</td>
                <td className="px-4 py-2">32</td>
                <td className="px-4 py-2">34</td>
                <td className="px-4 py-2">36</td>
                <td className="px-4 py-2">38</td>
                <td className="px-4 py-2">41</td>
                <td className="px-4 py-2">44</td>


              </tr>
              <tr>
                <td className="px-4 py-2">Waist</td>
                <td className="px-4 py-2">26</td>
                <td className="px-4 py-2">28</td>
                <td className="px-4 py-2">30</td>
                <td className="px-4 py-2">32</td>
                <td className="px-4 py-2">35</td>
                <td className="px-4 py-2">38</td>

              </tr>
              <tr>
                <td className="px-4 py-2">Hip</td>
                <td className="px-4 py-2">36</td>
                <td className="px-4 py-2">38</td>
                <td className="px-4 py-2">40</td>
                <td className="px-4 py-2">42</td>
                <td className="px-4 py-2">45</td>
                <td className="px-4 py-2">48</td>

              </tr>
             
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
  )
}

export default SizeChartModal