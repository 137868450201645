// import logo from './logo.svg';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar/Navbar';
import About from './Pages/About';
import ContactUs from './Pages/ContactUs';
import Tnc from './Pages/Tnc';
import Home from './Pages/Home';
import ReturnExchange from './Pages/ReturnExchange';
import Shipping from './Pages/Shipping';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Rasiyaa from './Pages/Rasiyaa';
import Lehengas from './Pages/Lehengas';
import Sarees from './Pages/Sarees';
import Anarkali from './Pages/Anarkali';
import Sahara from './Pages/Sahara';

function App() {
  return (
    <div className="app">
      <Navbar/>
       <Routes>
      {/* <Route path='/search' element={<SearchedItem />} /> */}

        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About  />} />
        <Route path='/contactus' element={<ContactUs />} />
        <Route path='/return-exchange' element={<ReturnExchange />} />
        <Route path='/tnc' element={<Tnc/>} />
        <Route path='/shipping' element={<Shipping/>} />
        <Route path='/tnc' element={<Tnc/>} />
        <Route path='/rasiyaa' element={<Rasiyaa/>} />
        <Route path='/lehengas' element={<Lehengas/>} />
        <Route path='/sarees' element={<Sarees/>} />
        <Route path='/anarkali' element={<Anarkali/>} />
        <Route path='/sharara-gharara' element={<Sahara/>} />



        <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
