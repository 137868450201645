import React, { useEffect, useState } from 'react'
import { SareeImg } from '../Components/SareeImages'
import Loader from '../Components/Loader';
import SortOptions from '../Components/SortOptions';
import _ from 'lodash';
import ProductHeader from '../Components/Navbar/ProductHeader';


const Sarees = () => {
  const[isLoading, setIsLoading] =useState(true);
  const [sortedProducts, setSortedProducts] = useState(SareeImg);
  

  const handleLoadComplete=()=>{
    setIsLoading(false);
  }
  
 
  return (
    <div>
        {isLoading ? ( // Display the loader while loading
    <Loader onLoadComplete={handleLoadComplete} />
    ) : (
      <>
      <ProductHeader
         sortedProducts={sortedProducts}
         setSortedProducts={setSortedProducts}
        />
      <div className='grid grid-cols-2 md:grid-cols-4 gap-4 mt-8 px-6'>
        {sortedProducts.map((image, index) => (
          <div key={index} className=' p-4'>
            <img
              src={image.imageUrl}
              alt={image.title}
              className='object-cover w-full h-auto shadow-md mb-4 transition-transform duration-300 transform hover:scale-110'
            />
            <h5 className='text-xs md:text-sm my-8 text-black-500 uppercase text-center uppercase font-small mb-2 tracking-wide'>{image.title}</h5>
            <p className='text-center text-xs'>Rs. {image.price}</p>
            
         
          </div>
        ))}
      </div>
      </>
    )}
    </div>
  )
}

export default Sarees