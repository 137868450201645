import React from 'react';
import { Link } from 'react-router-dom';
// import Subscribe from "./Subscribe.js"

const Footer = () => {
  return (
    <>
    {/* <Subscribe/> */}
    <footer className="bg-white text-black font-[sans-serif] ">
      <div className="container mx-auto py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Column 1: CUSTOMER CARE & POLICIES */}
          <div className="flex flex-col ml-8 space-y-4">
            <h2 className="text-sm font-semibold">Shop</h2>
            <Link to="/lehengas" className="hover:text-gray-300 text-xs">Lehenga sets</Link>
            <Link to="/sarees" className="hover:text-gray-300 text-xs">Saree sets</Link>
            <Link to="/anarkali" className="hover:text-gray-300 text-xs">Anarkali Sets</Link>
            <Link to="/sharara-gharara" className="hover:text-gray-300 text-xs">Sharara/garara sets</Link>
           
          </div>


          <div className="flex flex-col ml-8 space-y-4">
            <h2 className="text-sm font-semibold">Lookbook</h2>
            <Link to="/rasiyaa" className="hover:text-gray-300 text-xs">Rasiyaa</Link>

          </div>

          {/* Column 2: OUR COMPANY */}
          <div className="flex flex-col ml-8 space-y-4">
            <h2 className="text-sm font-semibold">Connect with us</h2>
            <Link to="/about" className="hover:text-gray-300 text-xs">About us</Link>
            <Link to="/contactus" className="hover:text-gray-300 text-xs">Contact Us</Link>

          </div>

          {/* Column 3: OUR BRANDS */}
          <div className="flex flex-col ml-8 space-y-4">
            <h2 className="text-sm font-semibold">Policies</h2>
            <Link to="/tnc" className="hover:text-gray-300 text-xs">Terms & conditions</Link>
      <Link to="/return-exchange" className="hover:text-gray-300 text-xs">Return & exchange</Link>
      <Link to="/shipping" className="hover:text-gray-300 text-xs">Shipping</Link>
      <Link to="/privacy-policy" className="hover:text-gray-300 text-xs">Privacy Policy</Link>
            
          </div>

          {/* Column 4: SHIPPING TO INDIA (INR) */}
          <div className="flex flex-col space-y-4">
            {/* <h2 className="text-lg font-semibold">SHIPPING TO INDIA (INR)</h2> */}
            {/* Add shipping details here */}
          </div>
        </div>
        <div className="border-t border-gray-700 mt-8 pt-4 mx-8 flex flex-col md:flex-row justify-center items-center">
          <p className="text-xs">&copy; {new Date().getFullYear()} <span className='hover:underline '>Mohbyrohitpatel</span> All rights reserved.</p>
          {/* <div className="flex mt-4 md:mt-0 space-x-4">
            <a href="#privacy" className="hover:text-gray-300">Privacy Policy</a>
            <a href="#terms" className="hover:text-gray-300">Terms of Service</a>
          </div> */}
        </div>
      </div>
    </footer>
    </>
  );
};

export default Footer;
