import React from 'react';

const Shipping = () => {
  return (
    <div className='flex flex-col  justify-center h-auto my-8 items-center font-[sans-serif]'>
      <h1 className='mb-6 uppercase font-[serif] text-2xl my-8'>SHIPPING POLICY</h1>
      <div className='text-start'>


        <p className='max-w-5xl mx-auto text-sm '>
          We are 'Made To Order', and hence time to dispatch may vary from 
          product to product. Normally, we would require 10-15 Days.
          You may reach us at,
           email-<a href='mailto:Mohbyrohitpatel@gmail.com?subject=Hello%20from%20Gmail'>
            Mohbyrohitpatel@gmail.com</a>  
             or Whatsapp number given on our Instagram profile so that
              we can assist you with the appropriate details for the product.
              </p>
               <span className='max-w-5xl mx-auto text-sm text-black font-semibold'>
          Please Note:- Post Shipment, the courier takes somewhere around 3 to 5 working
           days to deliver the item.
        </span> 
        <p className='max-w-5xl mx-auto text-sm '>
        Mohbyrohitpatel endeavours but does not guarantee delivery
         of products in the stipulated time period as mentioned on the purchase of the product.
          In no manner can the contract be repudiated if Mohbyrohitpatel fails to deliver any
           one or more products in the stipulated time frame. The Company is not liable for any delays
            due to natural calamities, national holidays, courier services etc.
            </p>
        

        <h2 className='mt-6 text-lg font-semibold'>Disclaimer:</h2>

        <p className='max-w-5xl mx-auto text-sm '>
          Courier costs are not included in the outfit price.
           The company shall only facilitate the delivery through the courier partner 
           and is not liable for any damages in transit. Couriers are solely at customers' risk.
          Free Shipping Within India.
          International Shipping Charges vary from country to country. (currently we are only accepting domestic orders)
          No cash on delivery available for domestic or international orders. (currently we are only accepting domestic orders)
          We do have Express Delivery. Kindly reach out to us, to check for the product availability.
          MOHBYROHITPATEL All Rights Reserved.
        </p>

      </div>
    </div>
  );
};

export default Shipping;
