export const RasiyaaImg = [
    {
        imageUrl: "/Images/Rasiyaa/54.jpg",
        title: 'Rani Pink Lehenga Set',
        price:"20,625"
      },
      {
        imageUrl: 'Images/Rasiyaa/4.jpg',
                  title: 'brocade chanderi anarkali set',
                  price:"20,250"
      },
      {
        imageUrl: "/Images/Rasiyaa/39.jpg",
                  title: 'Chocolate Organza saree set',
                  price:"17,625"
      },
     
      {
        imageUrl: 'Images/Rasiyaa/14.jpg',
                  title: "chanderi jhali gharara set",
                  price:"18,000"
      },
      {
        imageUrl: 'Images/Rasiyaa/2.jpg',
                  title: " Brocade chanderi lehenga set",
                  price:"20,250"
      },
      {
        imageUrl: 'Images/Rasiyaa/7.jpg',
                  title: " Brocade chanderi gharara set",
                  price:"18,000"
      },
      {
        imageUrl: 'Images/Rasiyaa/10.jpg',
                  title: "white silk chanderi jali lehenga",
                  price:"20,625"
      },
      {
        imageUrl: 'Images/Rasiyaa/16.jpg',
                  title: " silk chanderi scalloped saree set",
                  price:"17,250"
      },
      {
        imageUrl: 'Images/Rasiyaa/21.jpg',
                  title: " georgette ruffle saree set",
                  price:"18,375"
      },
      {
        imageUrl: 'Images/Rasiyaa/22.jpg',
                  title: " henna gota patti lehenga set",
                  price:"20,625"
      }, {
        imageUrl: 'Images/Rasiyaa/25.jpg',
                  title: "henna gota patti saree set",
                  price:"17,625"
      },
      {
        imageUrl: 'Images/Rasiyaa/28.jpg',
                  title: "mustard chevron lehenga set",
                  price:"17,625"
      },
      {
        imageUrl: 'Images/Rasiyaa/32.jpg',
                  title: "metallic gotta georgette saree set",
                  price:"21,375"
      },
      {
        imageUrl: 'Images/Rasiyaa/35.jpg',
                  title: "chocolate gota patti lehenga set",
                  price:"20,625"
      },
      {
        imageUrl: 'Images/Rasiyaa/42.jpg',
                  title: "tangerine lehenga set",
                  price:"21,375"
      },
      {
        imageUrl: 'Images/Rasiyaa/45.jpg',
                  title: "tangerine Scallop saree set",
                  price:"17,625"
      },
      {
        imageUrl: 'Images/Rasiyaa/48.jpg',
                  title: "yellow golden patti Lehenga set",
                  price:"20,625"
      },
      {
        imageUrl: 'Images/Rasiyaa/51.jpg',
                  title: "Black antique gold gota lehenga set",
                  price:"20,625"
      },
      {
        imageUrl: 'Images/Rasiyaa/56.jpg',
                  title: "Rani pink  gota patti saree set",
                  price:"17,625"
      }, {
        imageUrl: 'Images/Rasiyaa/59.jpg',
                  title: "Rani pink georgette saree set",
                  price:"16,875"
      },
      {
        imageUrl: 'Images/Rasiyaa/63.jpg',
                  title: "black organza saree set",
                  price:"16,125"
      },
     
     
      // Add more image objects as needed
    ];
      