import React from "react";
import { Link } from "react-router-dom";

const Button = () => {
  return (
    <Link to="/rasiyaa">

    <button className="relative text-sm bg-primary border text-white px-6 py-2 rounded-full transition-transform hover:bg-white hover:text-black hover:scale-105">
      Explore Now
    </button>
    </Link>
  );
};

export default Button;