import React from 'react'
import { IoLogoWhatsapp } from "react-icons/io";

const ContactUs = () => {
  return (
    <div className='flex justify-center h-[350px] my-6 items-center  font-[sans-serif]'>
      <div className='text-center '>

      <h1 className='mb-6 uppercase font-[serif] text-2xl  '>Contact US</h1>
            <p className='text-sm '>You may reach us at:</p>
        <p className=" font-semibold tracking-wider text-sm">Mohbyrohitpatel@gmail.com</p>
        <p className='text-sm '>or</p>
        <p className=" font-semibold tracking-wider text-sm">Whatsapp us at +918851314160</p>
      </div>
    </div>
  )
}

export default ContactUs