import React from 'react'
import "../App.css"

const SortOptions = ({ onSortChange, selectedOption }) => {
  return (
    <div className=''>
        
      <select
      id="sorting"
      className="border border-gray-300 rounded-md p-2 text-sm appearance-none focus:outline-none focus:border-gray-500  "
      value={selectedOption}
      onChange={onSortChange}
      >
       <option className='text-sm option-no-hover' value="">Sort By--</option>
        <option  className="option-no-hover" value="alphabetic-a-z">Alphabetic A-Z</option>
        <option   className="option-no-hover" value='alphabetic-z-a'>Alphabetic Z-A</option>
        <option  className="option-no-hover" value='price-low-to-high'>Price: Low to High</option>
        <option  className="option-no-hover" value='price-high-to-low'>Price: High to Low</option>

      </select>
     
    </div>
  )
}

export default SortOptions