export const SareeImg = [
   
   
      {
        imageUrl: "/Images/Rasiyaa/39.jpg",
                  title: 'Chocolate Organza saree set',
                  price:"17,625"
      },
     
      
    
    
      {
        imageUrl: 'Images/Rasiyaa/16.jpg',
                  title: " silk chanderi scalloped saree set",
                  price:"17,250"
      },
      {
        imageUrl: 'Images/Rasiyaa/21.jpg',
                  title: " georgette ruffle saree set",
                  price:"18,375"
      },
    {
        imageUrl: 'Images/Rasiyaa/25.jpg',
                  title: "henna gota patti saree set",
                  price:"17,625"
      },
      {
        imageUrl: 'Images/Rasiyaa/32.jpg',
                  title: "metallic gotta georgette saree set",
                  price:"17,625"
      },
     
      
     
      {
        imageUrl: 'Images/Rasiyaa/45.jpg',
                  title: "tangerine Scallop saree set",
                  price:"17,625"
      },
      
     
      {
        imageUrl: 'Images/Rasiyaa/56.jpg',
                  title: "Rani pink  gota patti saree set",
                  price:"17,625"
      }, {
        imageUrl: 'Images/Rasiyaa/59.jpg',
                  title: "Rani pink georgette saree set",
                  price:"16,875"
      },
      {
        imageUrl: 'Images/Rasiyaa/63.jpg',
                  title: "black organza saree set",
                  price:"16,125"
      },
     
     
      // Add more image objects as needed
    ];
      