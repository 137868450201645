import React, { useState } from 'react'
import Button from '../Components/Button'
import "../App.css"
import Card from '../Components/Card'
import Loader from '../Components/Loader'
// import Button from '../Components/Navbar/Button'

const Home = () => {
  const[isLoading, setIsLoading] =useState(true);

  const handleLoadComplete=()=>{
    setIsLoading(false);
  }
  return (
    <div>
       
        {isLoading ? ( // Display the loader while loading
    <Loader onLoadComplete={handleLoadComplete} />
    ) : (
        <div
      className="h-full bg-white  bg-cover
      font-[sans-serif] md:bg-top bg-center mb-8"
    > 
       {/* <Navbar /> */}
        {/* befor h-3/4-- */}
       <div className="flex flex-col justify-center gap-4 text-center items-center h-[700px] background-image"> 
        <h2 className="text-white text-5xl font-medium tracking-wide uppercase ">Rasiyaa</h2>
        <h1 className="md:text-xl text-3xl text-white tracking-wider  py-5">
         Festive Edit 
        </h1>
        <div className="text-xl ">
          <Button />
        </div>
      </div>
      <Card/>
      {/* <Footer/> */}
    </div>
    )}
    </div>
  )
}

export default Home