export const LehengasImg = [
    {
        imageUrl: "/Images/Rasiyaa/54.jpg",
        title: 'Rani Pink Lehenga Set',
        price:"20,625"
      },
     
      {
        imageUrl: 'Images/Rasiyaa/2.jpg',
                  title: " Brocade chanderi lehenga set",
                  price:"20,250"
      },
     
      {
        imageUrl: 'Images/Rasiyaa/10.jpg',
                  title: "white silk chanderi jali lehenga",
                  price:"20,625"
      },
      
      {
        imageUrl: 'Images/Rasiyaa/22.jpg',
                  title: "henna gota patti lehenga set",
                  price:"20,625"
      }, 
      {
        imageUrl: 'Images/Rasiyaa/28.jpg',
                  title: "mustard chevron lehenga set",
                  price:"21,375"
      },
     
      {
        imageUrl: 'Images/Rasiyaa/35.jpg',
                  title: "chocolate gota patti lehenga set",
                  price:"20,625"
      },
      {
        imageUrl: 'Images/Rasiyaa/42.jpg',
                  title: "tangerine lehenga set",
                  price:"21,375"
      },
     
      {
        imageUrl: 'Images/Rasiyaa/48.jpg',
                  title: "yellow golden patti Lehenga set",
                  price:"20,625"
      },
      {
        imageUrl: 'Images/Rasiyaa/51.jpg',
                  title: "Black antique gold gota lehenga set",
                  price:"20,625"
      },
     
     
     
      // Add more image objects as needed
    ];
      