import React from 'react'
import { Link } from 'react-router-dom'

const CardHeader = () => {
  return (
    <div>
        <div className='text-center  my-10 text-white'>

<h4 className='text-sm md:text-3xl uppercase my-4 tracking-wide text-black font-bold'>Rasiyaa</h4>

<h3 className='text-sm tracking-wide text-gray-600  md:text-lg max-w-5xl mx-auto  my-10 '>RASIYAA is a blend of traditional & modern silhouette. The collection is all about colours and elegant patterns of traditional Rajasthani gota patti work.</h3>
<h4 className='text-sm md:text-3xl uppercase mt-10 mb-2 tracking-wide text-black font-semibold	'>Explore</h4>
<Link to="/rasiyaa">
<button className="relative text-sm  mt-4 bg-primary uppercase border text-gray-500 px-6 py-2 rounded-full transition-transform hover:bg-gray-500 hover:text-black hover:scale-105">
view all
</button>
</Link>

</div>
    </div>
  )
}

export default CardHeader